// Code generated - EDITING IS FUTILE. DO NOT EDIT.
//
// Generated by:
//     public/app/plugins/gen.go
// Using jennies:
//     TSTypesJenny
//     PluginTSTypesJenny
//
// Run 'make gen-cue' from repository root to regenerate.

import * as ui from '@grafana/schema';

type ActionButtonType = "primary" | "green" | "danger" | "default"
type ActionButtonPosition = "left" | "right"
type ButtonField = {
  buttonName: string;
  columnName: string;
  type: ActionButtonType;
  width: string;
  position: ActionButtonPosition;
  code: string;
} 
export interface Options {
  /**
   * Controls the height of the rows
   */
  cellHeight?: ui.TableCellHeight;
  /**
   * Controls footer options
   */
  footer?: ui.TableFooterOptions;
  /**
   * Represents the index of the selected frame
   */
  frameIndex: number;
  /**
   * Controls whether the panel should show the header
   */
  showHeader: boolean;
  /**
   * Controls whether the header should show icons for the column types
   */
  showTypeIcons?: boolean;
  /**
   * Used to control row sorting
   */
  sortBy?: ui.TableSortByFieldState[];


  // addMultipleActionButton: boolean;
  // actionButtonName: string;
  // actionButtonWidth: number;
  // actionColumnName: string;
  // function: string;
  // buttonColumns: string[];
  // buttonNames: string[];
  // buttonTypes: string[];
  // buttonFunctions: string[];
  // buttonWidths: string[];
  // actionButtonType: ActionButtonType;

  addActionButton: boolean;
  buttons: ButtonField[];
  
  
}

export const defaultOptions: Partial<Options> = {
  cellHeight: ui.TableCellHeight.Sm,
  footer: {
    /**
     * Controls whether the footer should be shown
     */
    show: false,
    /**
     * Controls whether the footer should show the total number of rows on Count calculation
     */
    countRows: false,
    /**
     * Represents the selected calculations
     */
    reducer: [],
  },
  frameIndex: 0,
  showHeader: true,
  showTypeIcons: false,
  sortBy: [],
};

export interface FieldConfig extends ui.TableFieldOptions {}
